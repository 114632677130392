<template>
  <div v-if="assetList.length">
    <h3>{{ $t("found_assets") }}</h3>
    <v-list>
      <AssetDescription
        v-for="asset in assetList"
        :key="asset.objectid"
        :asset="asset"
        showButtons
        class="mb-2 pb-2 bottom-border"
        @setAsset="setAsset"
      />
    </v-list>
  </div>
  <div v-else>
    <h3>{{ $t("no_assets") }}</h3>
  </div>
</template>

<script>
import AssetDescription from "@/components/AssetDescription";

export default {
  name: "FoundAssets",
  components: {
    AssetDescription
  },
  props: {
    assetList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    setAsset({ assetDetails, route }) {
      this.$store.dispatch("resetMap");
      this.$store.dispatch("setAssetDetails", assetDetails);
      this.$router.push({ name: route });
    }
  }
};
</script>

<style scoped>
.bottom-border {
  border-bottom: 1px solid #bcbcbc;
}
</style>
