<template>
  <v-container class="pa-4">
    <CurrentlySelectedAsset showDetails />
    <v-divider class="my-6"></v-divider>
    <h2>{{ $t("select_intro_text") }}</h2>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="objectIdentifier"
            :label="$t('object_identifier')"
            outlined
            clearable
            autofocus
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label class="title">{{ $t("object_identifier_types") }}</v-label>
          <v-radio-group v-model="selectedIdentifierType">
            <v-radio
              v-for="identifier in identifierTypes"
              :key="identifier.value"
              :label="$t(identifier.name)"
              :value="identifier.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            @click="getDeviceId"
            :disabled="ableToSearch"
            color="primary"
            >{{ $t("search_device") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <FoundAssets class="mt-8 px-4" :assetList="foundDevices" />
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import FoundAssets from "@/components/FoundAssets";
import CurrentlySelectedAsset from "@/components/CurrentySelectedAsset";

export default {
  name: "Home",
  components: {
    FoundAssets,
    CurrentlySelectedAsset
  },
  data() {
    return {
      objectIdentifier: "",
      selectedIdentifierType: "objectcode",
      identifierTypes: [
        {
          name: "objectcode",
          value: "objectcode"
        },
        {
          name: "imei",
          value: "imei"
        },
        {
          name: "serialnumber",
          value: "serialnumber"
        },
        {
          name: "gsmnumber",
          value: "gsmnumber"
        }
      ],
      foundDevices: []
    };
  },
  computed: {
    ableToSearch() {
      if (this.objectIdentifier && this.selectedIdentifierType) {
        return false;
      }
      return true;
    }
  },
  created() {
    window.addEventListener("keypress", e => {
      if (e.keyCode === 13) {
        this.getDeviceId();
      }
    });
  },
  methods: {
    async getDeviceId() {
      if (!this.objectIdentifier || !this.selectedIdentifierType) {
        return false;
      }
      this.$store.commit("loading", true);
      const result = await callAPI({
        url: `${this.$store.state.device_api_url}/mf-devices?${
          this.selectedIdentifierType
        }=${encodeURIComponent(this.objectIdentifier)}`,
        method: "GET"
      });
      if (result) {
        this.foundDevices = result.data;
        this.$store.commit("loading", false);
      }
    }
  }
};
</script>
